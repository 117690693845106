import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import { sumField, obtenerClasePorAvance } from '../../services/funcionesPublicas'

import { Table } from 'react-bootstrap'

import './avances.scss'

const AvanceZona = () =>{

      const [ avance_zona, setRegistro ] = useState<any>([])

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint(`zonas/reporte_avance`)
            setRegistro(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);


      return (
          <>
            <div className="container-fluid">

                    <h4>Avance de Zona</h4>
                    <hr/>


                    <div className="row">
                      <div className="col-12 col-md-2">

                      <small>Zona</small>
                      <h2 className="text-center">{avance_zona.responsable?.zona}</h2>

                      </div>
                      <div className="col-12 col-md-10">
                        <small>Resúmen</small>

                    {
                      // JSON.stringify(avance_zona.secciones_)
                    }



                                                          <Table responsive="lg" size="sm">
                                                            <thead>
                                                              <tr>
                                                                <th className="text-center" scope="col">Municipio</th>
                                                                <th className="text-center" scope="col">Área</th>
                                                                <th className="text-center" scope="col">Universo</th>
                                                                <th className="text-center" scope="col">Meta</th>
                                                                <th className="text-center" scope="col">Viviendas</th>
                                                                <th className="text-center" scope="col">Censos</th>
                                                                <th className="text-center" scope="col">%</th>
                                                                <th className="text-center" scope="col">PAM</th>
                                                                <th className="text-center" scope="col">H</th>
                                                                <th className="text-center" scope="col">M</th>
                                                                <th className="text-center" scope="col">PCD</th>
                                                                <th className="text-center" scope="col">H</th>
                                                                <th className="text-center" scope="col">M</th>

                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                                      <tr >

                                                                        <td className="text-left v" colSpan={2}>
                                                                          <p className="nm"><b>TOTALES</b></p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_zona.secciones_||[],'universo_2024')}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_zona.secciones_||[],'meta')}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_zona.secciones_||[],'viviendas')}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_zona.secciones_||[],'folios')}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm"><b>{Math.round((sumField(avance_zona.secciones_||[],'folios')*100)/sumField(avance_zona.secciones_||[],'meta'))}</b></p>
                                                                        </td>
                                                                        <td className="text-center v pam" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_zona.secciones_||[],'adultos')}</p>
                                                                        </td>
                                                                        <td className="text-center v pam_minus" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_zona.secciones_||[],'adultos_hombres')}</p>
                                                                        </td>
                                                                        <td className="text-center v pam_minus" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_zona.secciones_||[],'adultos_mujeres')}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_zona.secciones_||[],'discapacidad')}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd_minus" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_zona.secciones_||[],'discapacidad_hombres')}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd_minus" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_zona.secciones_||[],'discapacidad_mujeres')}</p>
                                                                        </td> 

                                                                      </tr>

                                                              {
                                                                avance_zona?.secciones_?.map((seccion:any,indice_seccion:number)=>
                                                                      <tr key={indice_seccion}>

                                                                        <td className="text-left">
                                                                          <p className="nm">{seccion?.idmunicipio?.municipio}</p>
                                                                          {
                                                                            // obtenerClasePorAvance(Math.round((seccion.folios*100)/seccion.meta))
                                                                          }
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{seccion.seccion}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{seccion.universo_2024}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{seccion.meta}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{seccion.viviendas}</p>
                                                                        </td>
                                                                        <td className={`text-center v ${obtenerClasePorAvance(Math.round((seccion.folios*100)/seccion.meta))}`} style={{width:80}}>
                                                                          <p className="nm">{seccion.folios}</p>
                                                                        </td>
                                                                        <td className={`text-center v ${obtenerClasePorAvance(Math.round((seccion.folios*100)/seccion.meta))}`} style={{width:80}}>
                                                                          <p className="nm"><b>{Math.round((seccion.folios*100)/seccion.meta)}</b></p>
                                                                        </td>
                                                                        <td className="text-center v pam" style={{width:80}}>
                                                                          <p className="nm">{seccion.adultos}</p>
                                                                        </td>
                                                                        <td className="text-center v pam_minus" style={{width:80}}>
                                                                          <p className="nm">{seccion.adultos_hombres}</p>
                                                                        </td>
                                                                        <td className="text-center v pam_minus" style={{width:80}}>
                                                                          <p className="nm">{seccion.adultos_mujeres}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd" style={{width:80}}>
                                                                          <p className="nm">{seccion.discapacidad}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd_minus" style={{width:80}}>
                                                                          <p className="nm">{seccion.discapacidad_hombres}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd_minus" style={{width:80}}>
                                                                          <p className="nm">{seccion.discapacidad_mujeres}</p>
                                                                        </td> 

                                                                      </tr>
                                                                  )
                                                              }


                                                            </tbody>
                                                          </Table>




                    
                </div>
              </div>

            </div>

          </>

      );
  

}

export default AvanceZona
