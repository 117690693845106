import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalDetalleFolio from './ModalDetalleFolio'


import ModalReporteAvancesCensoExcel from './ModalReporteAvancesCensoExcel'

import Dialog from '../../services/Dialogs'

import AuthService from '../../features/auth/auth.service'


import './censo.scss';

const Censo_Folios = () =>{



const dialog = new Dialog();

  const user = AuthService.getCurrentUser()


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ folios, setFolios ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`censo/folios/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setFolios(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);





  const [ show_ModalDetalleFolio, setModalDetalleFolio ] = useState<boolean>(false)
  const [ folio_seleccionado, setFolioSeleccionado ] = useState<any>(null)
  const handleShow_ModalDetalleFolio = (folio:any) => {
    setFolioSeleccionado(folio)
    setModalDetalleFolio(true)
  }
  const handleClose_ModalDetalleFolio = () => {
    // busqueda()
    setModalDetalleFolio(false)
  }




  const [ show_ModalReporteAvancesCensoExcel, setModalReporteAvancesCensoExcel ] = useState<boolean>(false)
  const handleShow_ModalReporteAvancesCensoExcel = () => {
    setModalReporteAvancesCensoExcel(true)
  }
  const handleClose_ModalReporteAvancesCensoExcel = () => {
    setModalReporteAvancesCensoExcel(false)
  }




const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const enviarFormulario = async (event:any, folio:any) =>{
  // console.log(folio)
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<small>El Folio se marcará de cancelado</small>
                    <label>Motivo de la Cancelación</label>
                    <textarea style="width:100%" rows="6" name="motivo_cancelacion" value=""></textarea>
                    
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `censo/cancelar_folio/${folio.idfolio}` , { motivo_cancelacion : dialogo.motivo_cancelacion })

        if(res_){
          toast.warning('El Folio fué marcado como CANCELADO. ')
          setTimeout(()=>{
            cargarMiReporte(currentPage===0?0:currentPage-1)
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("No pudimos realizar la acción")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}

const eliminarCenso = async (event:any, folio:any) =>{
  // console.log(folio)
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<small>Este folio se eliminará así como  <br/>los folios asociados a esta visita.</small>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `censo/eliminar_censo/${folio.uuid_censo}`)


        if(res_){
          toast.warning('El Folio fué ELIMINADO. Esperamos que sepas lo que haces.')
          setTimeout(()=>{
            busqueda()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("No pudimos realizar la acción")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}




      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Búsqueda de Folio del Censo</h4>
                    <hr/>





        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            


          <ul className="list-inline">
            <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalReporteAvancesCensoExcel()}}><i className="fa fa-file-excel-o"/> Reporte de Censos</small></li>
          </ul>



        </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     folios.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col">Capturó</th>
                                              <th scope="col">Captura</th>
                                              <th scope="col">Migración</th>
                                              <th scope="col">Sexo</th>
                                              <th scope="col">Región</th>
                                              <th scope="col">Microregión</th>
                                              <th scope="col">Zona</th>
                                              <th scope="col">Área</th>
                                              <th colSpan={2} className="text-center" scope="col">Casos</th>
                                              {/*<th scope="col">Discapacidad</th>*/}
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              
                                                    {
                                                      ["ROLE_ADMIN"].indexOf(user.rol) > -1 && (
                                                          <th scope="col"></th>
                                                        )
                                                    }
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              folios.map( (folio:any,indice_folio:number) =>
                                                  <tr className={` ${ folio.cancelado ? 'folio_cancelado' : '' } `} key={indice_folio} >
                                                    <td className="v text-left" style={{"width":"250px"}} >
                                                        <h4 className="nm">{folio.folio}</h4>
                                                    </td>
                                                    <td className="v text-left" >
                                                        <p className="nm"><b>{folio.nombres}</b></p>
                                                        <small>{folio.paterno} {folio.materno}</small>
                                                        {/*<p className="nm"><i className="fa fa-calendar" /> {new Date(folio.fecha_censo).toLocaleString()} - <i className="fa fa-upload" /> {new Date(folio.fecha_creacion).toLocaleString()}</p>*/}
                                                    </td>
                                                    <td className="v text-left" style={{"width":"250px"}}>
                                                        <p className="nm">{new Date(folio.fecha_censo).toLocaleString()}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"250px"}}>
                                                        <p className="nm">{new Date(folio.fecha_creacion).toLocaleString()}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm">{folio.sexo==='H'?'HOMBRE':'MUJER'} </p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                        <p className="nm">{folio.region}</p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                        <p className="nm">{folio.microregion}</p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                        <p className="nm">{folio.zona}</p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                        <p className="nm">{folio.seccion}</p>
                                                    </td>
                                                    <td className={`v text-center ${folio.adulto?'adulto':''}`} style={{"width":"60px",fontSize:30}}>
                                                      {
                                                        folio.adulto && (<> { folio.sexo === 'H' ? <>👴🏻</> : <>👵🏻</> } </>)
                                                      }
                                                    </td>
                                                    <td className={`v text-center ${folio.discapacidad?'discapacidad  ':''}`} style={{"width":"60px",fontSize:20}}>
                                                      {
                                                        folio.discapacidad && (<>♿️</>)
                                                      }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"20px"}}>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"80px"}}>
                                                      <button className="btn bt-link" onClick={(e:any)=>{handleShow_ModalDetalleFolio(folio)}}><i className="fa fa-archive"/></button>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                      {
                                                        !folio.cancelado && (<button className="btn  btn-sm btn-danger" onClick={(e:any)=>{enviarFormulario(e,folio)}}><i className="fa fa-remove"/></button>)
                                                      }
                                                    </td>
                                                    {
                                                      ["ROLE_ADMIN"].indexOf(user.rol) > -1 && (
                                                          <td className="v text-left" style={{"width":"40px"}}>
                                                            {
                                                              true && (<button className="btn  btn-sm btn-link" onClick={(e:any)=>{eliminarCenso(e,folio)}}><i className="fa fa-trash"/></button>)
                                                            }
                                                          </td>
                                                        )
                                                    }
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



                    
                </div>
              </div>

            </div>





  {
    show_ModalDetalleFolio && (
      <ModalDetalleFolio show={show_ModalDetalleFolio} handleClose={handleClose_ModalDetalleFolio} folio={folio_seleccionado} />
      )

  }




  {
    show_ModalReporteAvancesCensoExcel && (
      <ModalReporteAvancesCensoExcel show={show_ModalReporteAvancesCensoExcel} handleClose={handleClose_ModalReporteAvancesCensoExcel}  />
      )

  }


          </>

      );
  

}

export default Censo_Folios
