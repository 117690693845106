import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import { Table } from 'react-bootstrap'

import { sumField, obtenerClasePorAvance } from '../../services/funcionesPublicas'

import './avances.scss'



const AvanceMicroregion = () =>{

      const [ avance_microregion, setRegistro ] = useState<any>([])

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint(`microregion/reporte_avance`)
            setRegistro(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);


      return (
          <>
            <div className="container-fluid">


                    <h4>Avance de Microregion</h4>
                    <hr/>



                    <div className="row">
                      <div className="col-12 col-md-3">

                      <small>Microregión</small>
                      <h2 className="text-center">{avance_microregion.responsable?.microregion}</h2>

                    {
                      // JSON.stringify(avance_microregion.responsabes_zonas)
                    }
{/*
                      <h3>Selecciona Usuarios</h3>
                      <div className="list-group">
                          {avance_microregion?.responsabes_zonas?.map((usuario:any,indice_usuario:number) => (
                              <div className="list-group-item" key={indice_usuario}>
                                  <label htmlFor={usuario.uuid} className="ms-2">
                                  <input
                                      type="checkbox"
                                      checked={usuario.seleccionado}
                                      onChange={(e:any) =>{ let a = [...avance_microregion?.responsabes_zonas]; a[indice_usuario].seleccionado = e.target.checked;  setRegistro({...avance_microregion, responsabes_zonas : a})}}
                                      id={usuario.uuid}
                                  />
                                  {' '}
                                    <small style={{fontWeight:'normal'}}>ZONA {usuario.zona}</small> 
                                   {' '}
                                      {`${usuario.nombres} ${usuario.paterno} ${usuario.materno}`}
                                  </label>
                              </div>
                          ))}
                      </div>
                      <br/>*/}

                      </div>
                      <div className="col-12 col-md-9">
                        
                        <h6>Resúmen</h6>



                                                          <Table responsive="lg" size="sm">
                                                            <thead>
                                                              <tr>
                                                                <th className="text-center" scope="col">Municipio</th>
                                                                <th className="text-center" scope="col">Zona</th>
                                                                <th className="text-center" scope="col">Área</th>
                                                                <th className="text-center" scope="col">Universo</th>
                                                                <th className="text-center" scope="col">Meta</th>
                                                                <th className="text-center" scope="col">Viviendas</th>
                                                                <th className="text-center" scope="col">Censos</th>
                                                                <th className="text-center" scope="col">%</th>
                                                                <th className="text-center" scope="col">PAM</th>
                                                                <th className="text-center" scope="col">H</th>
                                                                <th className="text-center" scope="col">M</th>
                                                                <th className="text-center" scope="col">PCD</th>
                                                                <th className="text-center" scope="col">H</th>
                                                                <th className="text-center" scope="col">M</th>

                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                                      <tr >

                                                                        <td className="text-left v" colSpan={3}>
                                                                          <p className="nm"><b>TOTALES</b></p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_microregion.secciones_||[],'universo_2024')}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_microregion.secciones_||[],'meta')}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_microregion.secciones_||[],'viviendas')}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_microregion.secciones_||[],'folios')}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm"><b>{Math.round((sumField(avance_microregion.secciones_||[],'folios')*100)/sumField(avance_microregion.secciones_||[],'meta'))}</b></p>
                                                                        </td>
                                                                        <td className="text-center v pam" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_microregion.secciones_||[],'adultos')}</p>
                                                                        </td>
                                                                        <td className="text-center v pam_minus" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_microregion.secciones_||[],'adultos_hombres')}</p>
                                                                        </td>
                                                                        <td className="text-center v pam_minus" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_microregion.secciones_||[],'adultos_mujeres')}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_microregion.secciones_||[],'discapacidad')}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd_minus" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_microregion.secciones_||[],'discapacidad_hombres')}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd_minus" style={{width:80}}>
                                                                          <p className="nm">{sumField(avance_microregion.secciones_||[],'discapacidad_mujeres')}</p>
                                                                        </td> 

                                                                      </tr>

                                                              {
                                                                avance_microregion?.secciones_?.map((seccion:any,indice_seccion:number)=>
                                                                      <tr key={indice_seccion}>

                                                                        <td className="text-left">
                                                                          <p className="nm">{seccion?.idmunicipio?.municipio}</p>
                                                                          {
                                                                            // obtenerClasePorAvance(Math.round((sumField(avance_microregion.secciones_||[],'folios')*100)/sumField(avance_microregion.secciones_||[],'meta')))
                                                                          }
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{seccion.zona}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{seccion.seccion}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{seccion.universo_2024}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{seccion.meta}</p>
                                                                        </td>
                                                                        <td className="text-center v" style={{width:80}}>
                                                                          <p className="nm">{seccion.viviendas}</p>
                                                                        </td>
                                                                        <td className={`text-center v ${obtenerClasePorAvance(Math.round((seccion.folios*100)/seccion.meta))}`} style={{width:80}}>
                                                                          <p className="nm">{seccion.folios}</p>
                                                                        </td>
                                                                        <td className={`text-center v ${obtenerClasePorAvance(Math.round((seccion.folios*100)/seccion.meta))}`} style={{width:80}}>
                                                                          <p className="nm"><b>{Math.round((seccion.folios*100)/seccion.meta)}</b></p>
                                                                        </td>
                                                                        <td className="text-center v pam" style={{width:80}}>
                                                                          <p className="nm">{seccion.adultos}</p>
                                                                        </td>
                                                                        <td className="text-center v pam_minus" style={{width:80}}>
                                                                          <p className="nm">{seccion.adultos_hombres}</p>
                                                                        </td>
                                                                        <td className="text-center v pam_minus" style={{width:80}}>
                                                                          <p className="nm">{seccion.adultos_mujeres}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd" style={{width:80}}>
                                                                          <p className="nm">{seccion.discapacidad}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd_minus" style={{width:80}}>
                                                                          <p className="nm">{seccion.discapacidad_hombres}</p>
                                                                        </td>
                                                                        <td className="text-center v pcd_minus" style={{width:80}}>
                                                                          <p className="nm">{seccion.discapacidad_mujeres}</p>
                                                                        </td> 

                                                                      </tr>
                                                                  )
                                                              }


                                                            </tbody>
                                                          </Table>


                      </div>
                    </div>


                    

            </div>

          </>

      );
  

}

export default AvanceMicroregion
