import React, { useState, useEffect, useRef } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import ImageLayer from 'ol/layer/Image';
import Image from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';

import { Navbar, Container, Row, Col, Button, Tab, Tabs } from "react-bootstrap";
import './posicionamiento.scss';  // Importa el archivo CSS





import RequestService from "../../services/request.service";


import { Form, Modal } from 'react-bootstrap'

import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'




const Posicionamiento = () => {

  const coordinates = [ -90.52897668399505,19.84632033719966 ];

  const transformedCoordinates = fromLonLat(coordinates, 'EPSG:3857');

  const estadoLayerRef = useRef<any>(null);

  const distritosLayerRef = useRef<any>(null);
  const seccionesLayerRef = useRef<any>(null);



  const regionesLayerRef = useRef<any>(null);
  const microregionesLayerRef = useRef<any>(null);
  const zonasLayerRef = useRef<any>(null);
  const zonasFederalesLayerRef = useRef<any>(null);




  const zonasPrioridadLayerRef = useRef<any>(null);

  const heatmapEncuestaSalidaLayerRef = useRef<any>(null);


  const coloniasLayerRef = useRef<any>(null);


  const mapRef = useRef<any>(null);

  const [bbox, setBbox] = useState<string | null>(null);


  useEffect(() => {


    const estadoLayer = new TileLayer({
                          source: new OSM(),
                        });


    // const estadoLayer = new ImageLayer({
    //   source: new ImageWMS({
    //     url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/censo_base.map',
    //     params: {
    //       'LAYERS': 'estado',
    //       'FORMAT': 'image/png',
    //       'TRANSPARENT': false,
    //       'VERSION': '1.3.0',
    //       'CRS': 'EPSG:3857',
    //     },
    //     serverType: 'mapserver',
    //   }),
    // });







    // Capa para "distritos"
    const distritosLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/distritos.map',
        params: {
          'LAYERS': 'distritos',
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });

    // Capa para "secciones"
    const seccionesLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/areas.map',
        params: {
          'LAYERS': 'secciones',
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });



    //// CENSO LAYERS

    // Capa para "secciones"
    const regionesLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/shp_regiones_bienestar.map',
        params: {
          'LAYERS': 'regiones',
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });

    // Capa para "secciones"
    const microregionesLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/shp_microregiones_bienestar.map',
        params: {
          'LAYERS': 'microregiones',
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });

    // Capa para "secciones"
    const zonasLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/shp_zonas_bienestar.map',
        params: {
          'LAYERS': 'zonas',
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });
    // Capa para "secciones"
    const zonasFederalesLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/shp_zonas_bienestar_federal.map',
        params: {
          'LAYERS': 'zonas',
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });



    ////////////////////////////////////////////
    ////////////////////////////////////////////


    /// ZONAS DE PRIORIDAD


    // Capa para "secciones y prioridad"
    const zonasPrioridadLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/zonas_prioridad.map',
        params: {
          'LAYERS': 'oro,plata,bronce,quemadas',
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });




    /// HEATMAPS LAYERS


    // Capa para "encuesta de salida"
    const heatmapEncuestaSalidaLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/heatmap_censo_bienestar.map',
        params: {
          'LAYERS': 'heatmap',
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });



    /// OTRAS LAYERS


    // Capa para "encuesta de salida"
    const coloniasLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/colonias.map',
        params: {
          'LAYERS': 'colonias',
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });






    // Guardar las capas en las referencias
    estadoLayerRef.current = estadoLayer;

    seccionesLayerRef.current = seccionesLayer;
    distritosLayerRef.current = distritosLayer;

    zonasPrioridadLayerRef.current = zonasPrioridadLayer;

    heatmapEncuestaSalidaLayerRef.current = heatmapEncuestaSalidaLayer;



    regionesLayerRef.current = regionesLayer;
    microregionesLayerRef.current = microregionesLayer;
    zonasLayerRef.current = zonasLayer;
    zonasFederalesLayerRef.current = zonasFederalesLayer;

    coloniasLayerRef.current = coloniasLayer;

    const map = new Map({
      target: 'map', // ID del elemento donde se renderizará el mapa
      layers: [
                  estadoLayer, 

                  coloniasLayer,


                  zonasPrioridadLayer, 
                  zonasFederalesLayer, 

                  seccionesLayer, 
                  distritosLayer,

                  regionesLayer,
                  microregionesLayer,
                  zonasLayer,

                  heatmapEncuestaSalidaLayer,

              ],
      // [




                    // new Image({
                    //   source: new ImageWMS({
                    //     url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/helpnet_base.map',
                    //     params: {
                    //       'LAYERS': 'estado',
                    //       'FORMAT': 'image/png',
                    //       'TRANSPARENT': false,
                    //       'VERSION': '1.3.0',
                    //       'CRS': 'EPSG:3857',
                    //       // 'WIDTH': 1800, // Ajusta el tamaño según tus necesidades
                    //       // 'HEIGHT': 1800 // Ajusta el tamaño según tus necesidades
                    //     },
                    //     serverType: 'mapserver', // Ajusta esto según tu servidor WMS
                    //   }),
                    // }),


                    // new Image({
                    //   source: new ImageWMS({
                    //     url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/secciones.map',
                    //     params: {
                    //       'LAYERS': 'secciones',
                    //       'FORMAT': 'image/png',
                    //       'TRANSPARENT': true,
                    //       'VERSION': '1.3.0',
                    //       'CRS': 'EPSG:3857',
                    //       // 'WIDTH': 1800, // Ajusta el tamaño según tus necesidades
                    //       // 'HEIGHT': 1800 // Ajusta el tamaño según tus necesidades
                    //     },
                    //     serverType: 'mapserver', // Ajusta esto según tu servidor WMS
                    //   }),
                    // })


      // ],
            view: new View({
                center: transformedCoordinates,
                zoom: 12
              }),
    });

    mapRef.current = map


    const getBoundingBox = () => {
      const view = map.getView();
      const extent = view.calculateExtent(map.getSize());
      return extent;
    };

    // Escuchar los cambios en el mapa (zoom, movimiento)
    map.on('moveend', () => {
      const bbox = getBoundingBox();
      setBbox(bbox.join(', ')); // Actualizar el estado del BBOX
      // console.log('BBOX actual:', bbox); // O mostrarlo en consola
    });


    return () => map.setTarget(undefined); // Limpieza al desmontar el componente
  }, []);


  const toggleLayer = (layerRef:any, visible:any) => {
    if (layerRef.current) {
      layerRef.current.setVisible(visible);
    }
  };



  const [ capas, setCapas ] = useState<any>({

    estado : true,

    distritos : false,

    secciones : false,

    regiones : false,
    microregiones : false,
    zonas : false,
    zonas_federales : false,


    zonas_prioridad : false,

    heatmap_encuesta_salida : true,



    colonias : false,

  }) 

  // Efecto para controlar la visibilidad de las capas
  useEffect(() => {
    toggleLayer(seccionesLayerRef, capas.secciones);
    toggleLayer(distritosLayerRef, capas.distritos);
    toggleLayer(estadoLayerRef, capas.estado);


    toggleLayer(regionesLayerRef, capas.regiones);
    toggleLayer(microregionesLayerRef, capas.microregiones);
    toggleLayer(zonasLayerRef, capas.zonas);
    toggleLayer(zonasFederalesLayerRef, capas.zonas_federales);

    toggleLayer(zonasPrioridadLayerRef, capas.zonas_prioridad);

    toggleLayer(heatmapEncuestaSalidaLayerRef, capas.heatmap_encuesta_salida);



    toggleLayer(coloniasLayerRef, capas.colonias);

  }, [capas]);


  const moveToCoordinates = () => {
    const newCoordinates = fromLonLat([-90.52897668399505,19.84632033719966], 'EPSG:3857');
    if (mapRef.current) {
      mapRef.current.getView().setCenter(newCoordinates);
    }
  };



















  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
      microregion : yup.number().required(mensajes_validacion_generico),
      seccion : yup.number().required(mensajes_validacion_generico),
      zona : yup.number().required(mensajes_validacion_generico),
      municipio : yup.string().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `URL_REQUEST` , { ...registro, microregion : `MICROREGIÓN ${registro.microregion}` })

          if(res_){
            toast.success('MENSAJE')
            setTimeout(()=>{
              toast.success('Chingón')
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }

























  return (

      <React.Fragment>


      <Container fluid className="main-container">
        <Row className="h-100">
          {/* Columna izquierda para los controles y capas */}
          <Col xs={12} md={3} >


                      <h5>Controles del Mapa</h5>



                      <h6>Electoral</h6>

                          <label>
                          <input type="checkbox" checked={capas.regiones} onChange={(e:any)=>{ let c = {...capas}; c.regiones = e.target.checked; setCapas(c) }} />
                          {' '}
                            Regiones
                          </label>

                          <label>
                          <input type="checkbox" checked={capas.microregiones} onChange={(e:any)=>{ let c = {...capas}; c.microregiones = e.target.checked; setCapas(c) }} />
                          {' '}
                            Microregiones
                          </label>

                          <label>
                          <input type="checkbox" checked={capas.zonas} onChange={(e:any)=>{ let c = {...capas}; c.zonas = e.target.checked; setCapas(c) }} />
                          {' '}
                            Zonas
                          </label>


                          <label>
                          <input type="checkbox" checked={capas.zonas_federales} onChange={(e:any)=>{ let c = {...capas}; c.zonas_federales = e.target.checked; setCapas(c) }} />
                          {' '}
                            Zonas Federales
                          </label> 



                          <label>
                          <input type="checkbox" checked={capas.secciones} onChange={(e:any)=>{ let c = {...capas}; c.secciones = e.target.checked; setCapas(c) }} />
                          {' '}
                            Áreas
                          </label>


                          <label>
                          <input type="checkbox" checked={capas.heatmap_encuesta_salida} onChange={(e:any)=>{ let c = {...capas}; c.heatmap_encuesta_salida = e.target.checked; setCapas(c) }} />
                          {' '}
                            Zonas de calor Censo Bienestar 2024
                          </label>
                          



                      <h6 className="mt-2">Geografía</h6>





                          <label>
                          <input type="checkbox" checked={capas.colonias} onChange={(e:any)=>{ let c = {...capas}; c.colonias = e.target.checked; setCapas(c) }} />
                          {' '}
                            Colonias
                          </label>

                          <br/>
                          
 






          </Col>
          <Col xs={12} md={9}>

            <div id="map" style={{ width: '100%', height: '100%', minHeight : 500 }} />

          </Col>
        </Row>
      </Container>



        

      </React.Fragment>


    );
};

export default Posicionamiento;
