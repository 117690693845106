import React, { useState, useEffect } from 'react'

import { Link, Route, Routes, useLocation } from 'react-router-dom'


import { Container, Navbar, Nav , NavDropdown } from 'react-bootstrap'


import { PrivateRoute } from './PrivateRoute'

import NotFound from './pages/NotFound'
import Login from './pages/Login'
import Profile from './pages/Profile'



import Verificacion from './pages/Verificacion'



import { ROLE } from './features/auth/role'

import AuthService from './features/auth/auth.service'





// import Padron from './pages/Padron/Padron'


// import EstarBien from './pages/EstarBien/EstarBien'



// import Analiticas from './pages/Analiticas/Analiticas'







import Usuarios from './pages/Usuarios/Usuarios'





import MisFolios from './pages/Capturista/MisFolios'













import Avances from './pages/Avances/Avances'
import AvanceZona from './pages/Avances/AvanceZona'
import AvanceArea from './pages/Avances/AvanceArea'
import AvanceMicroregion from './pages/Avances/AvanceMicroregion'


import Secciones from './pages/Secciones/Secciones'




import Posicionamiento from './pages/Posicionamiento/Posicionamiento'


import Censo_Reporte from './pages/Censo/Censo_Reporte'
import Censo_Folios from './pages/Censo/Censo_Folios'



import './App.scss';


function BootHeader() {

	const user = AuthService.getCurrentUser()

	const isAuthenticated = AuthService.selectIsAuthenticated
	let location = useLocation();

	// let activo_perfil = location.pathname === '/profile' ? 'active' : ''
	// let activo_folios = location.pathname === '/censo_busqueda_folios' ? 'active' : ''
	// let activo_secciones = location.pathname === '/secciones' ? 'active' : ''






	const [ ROLE_ADMIN, setAdmin ] = useState(false)

	const [ ROL_REGION, setRegion ] = useState(false)
	const [ ROL_MICROREGION, setMicroregion ] = useState(false)
	const [ ROL_ZONA, setZona ] = useState(false)
	const [ ROL_AREA, setArea ] = useState(false)




 
	useEffect(() => {

	  if(user.rol === "ROLE_ADMIN") setAdmin(true)

	  if(user.rol === "ROL_REGION") setRegion(true)
	  if(user.rol === "ROL_MICROREGION") setMicroregion(true)
	  if(user.rol === "ROL_ZONA") setZona(true)
	  if(user.rol === "ROL_AREA") setArea(true)





	},[ 
				ROLE_ADMIN, 
				ROL_REGION,
				ROL_MICROREGION,
				ROL_ZONA,
				ROL_AREA,



				user]);



	const imagenNavBar = () =>{

		let img = `/img/escudo.png`

		// if(["ROLE_LIMPIEZA","ROLE_TECNICO","ROLE_CONSERVACION"].indexOf(user.rol) > -1) img = `/img/selin_navbar_operacion.png`
		// if(["ROLE_CLIENTE","ROLE_BOUTIQUE"].indexOf(user.rol) > -1) img = `/img/selin_navbar_clientes.png`
		// if(["ROLE_CLIENTE","ROLE_BOUTIQUE"].indexOf(user.rol) > -1) img = `/img/selin_navbar_clientes.png`
		// if(["ROLE_RESPONSABLE_CONSERVACION","ROLE_RESPONSABLE_LIMPIEZA","ROLE_RESPONSABLE_TECNICOS","ROLE_RESPONSABLE_OPERACION","ROLE_RECURSOS_MATERIALES"].indexOf(user.rol) > -1) img = `/img/selin_navbar_responsables.png`

		return img


	}


  return (
    <React.Fragment>

	    { 
	    	isAuthenticated && ( 
						    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
						      <Container fluid>
						        <Navbar.Brand><h4 className="nm"></h4></Navbar.Brand>

					          <Navbar.Brand href="#home">
					            <img
					              alt=""
					              src={ imagenNavBar() }
					              height="40"
					              className="d-inline-block align-top"
					            />{' '}
					          </Navbar.Brand>


						        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
						        <Navbar.Collapse id="responsive-navbar-nav">
						          <Nav className="me-auto" defaultActiveKey={location.pathname}>


		                 { ( ROLE_ADMIN ) && (
		                 		<>
		                     <Nav.Link  href="/profile">Perfil</Nav.Link>
		                     <Nav.Link  href="/usuarios">Usuarios</Nav.Link>

		                     <Nav.Link  href="/secciones">Secciones</Nav.Link>
		                     <Nav.Link  href="/avances">Avances</Nav.Link>
		                     <Nav.Link  href="/posicionamiento">Posicionamiento</Nav.Link>
		                     <Nav.Link  href="/censo_busqueda_folios">Folios</Nav.Link>

		                 		</>
		                 )}



		                 { ( ROL_REGION ) && (
		                 		<>
		                     <Nav.Link  href="/profile">Perfil</Nav.Link>
		                     <Nav.Link  href="/posicionamiento">Posicionamiento</Nav.Link>
		                     <Nav.Link  href="/mis_folios">Mis Folios</Nav.Link>
		                     <Nav.Link  href="/censo_busqueda_folios">Folios</Nav.Link>

		                 		</>
		                 )}


		                 { ( ROL_MICROREGION ) && (
		                 		<>
		                     <Nav.Link  href="/profile">Perfil</Nav.Link>
		                     <Nav.Link  href="/posicionamiento">Posicionamiento</Nav.Link>
		                     <Nav.Link  href="/avance_microregion">Avance</Nav.Link>
		                     <Nav.Link  href="/mis_folios">Mis Folios</Nav.Link>
		                     <Nav.Link  href="/censo_busqueda_folios">Folios</Nav.Link>

		                 		</>
		                 )}



		                 { ( ROL_ZONA ) && (
		                 		<>
		                     <Nav.Link  href="/profile">Perfil</Nav.Link>
		                     <Nav.Link  href="/mis_folios">Mis Folios</Nav.Link>
		                     <Nav.Link  href="/censo_reporte">Reporte</Nav.Link>
		                     <Nav.Link  href="/avance_zona">Avance</Nav.Link>
		                     <Nav.Link  href="/censo_busqueda_folios">Folios</Nav.Link>

		                 		</>
		                 )}


		                 { ( ROL_AREA ) && (
		                 		<>
		                     <Nav.Link  href="/profile">Perfil</Nav.Link>
		                     <Nav.Link  href="/mis_folios">Mis Folios</Nav.Link>
		                     <Nav.Link  href="/mi_avance">Mi Avance</Nav.Link>

		                 		</>
		                 )}




						          </Nav>
						          <Nav>
						            <Nav.Link href="/profile">{user.nombres}</Nav.Link>
						          </Nav>
						        </Navbar.Collapse>
						      </Container>
						    </Navbar>
	    	) 
	    }




    </React.Fragment>
  )

}

function App() {

  return (
    <>
    
      <BootHeader />

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />

        <Route path="profile" element={<PrivateRoute roles={[ ROLE.ADMIN, ROLE.ROL_REGION, ROLE.ROL_MICROREGION, ROLE.ROL_ZONA, ROLE.ROL_AREA ]} component={Profile} />} />





        <Route path="mis_folios" element={<PrivateRoute roles={[ ROLE.ADMIN, ROLE.ROL_REGION, ROLE.ROL_MICROREGION, ROLE.ROL_ZONA, ROLE.ROL_AREA ]} component={MisFolios} />} />








        <Route path="usuarios" element={<PrivateRoute roles={[ ROLE.ADMIN ]} component={Usuarios} />} />



        <Route path="posicionamiento" element={<PrivateRoute roles={[ ROLE.ADMIN, ROLE.ROL_REGION, ROLE.ROL_MICROREGION ]} component={Posicionamiento} />} />


        <Route path="avances" element={<PrivateRoute roles={[ ROLE.ADMIN, ROLE.ROL_REGION, ROLE.ROL_MICROREGION, ROLE.ROL_REGION, ROLE.ROL_AREA ]} component={Avances} />} />
        <Route path="mi_avance" element={<PrivateRoute roles={[ ROLE.ROL_AREA ]} component={AvanceArea} />} />
        <Route path="avance_zona" element={<PrivateRoute roles={[ ROLE.ROL_ZONA ]} component={AvanceZona} />} />
        <Route path="avance_microregion" element={<PrivateRoute roles={[ ROLE.ROL_MICROREGION ]} component={AvanceMicroregion} />} />

        <Route path="secciones" element={<PrivateRoute roles={[ ROLE.ADMIN ]} component={Secciones} />} />


        <Route path="censo_reporte" element={<PrivateRoute roles={[ ROLE.ADMIN, ROLE.ROL_ZONA ]} component={Censo_Reporte} />} />
        <Route path="censo_busqueda_folios" element={<PrivateRoute roles={[ ROLE.ADMIN, ROLE.ROL_MICROREGION, ROLE.ROL_REGION, ROLE.ROL_ZONA ]} component={Censo_Folios} />} />





{/*        <Route path="administracion" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={Administracion} />} >
						<Route path="equipos_criticos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={EquiposCriticos} />} />        	
						<Route path="zonas" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={Zonas} />} />        	
						<Route path="subzonas" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ROLE_JEFE_ADMIN ]} component={Subzonas} />} />        	
        </Route>
*/}








      </Routes>

    </>
  )
}

export default App
