import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";
import { Table, Form, Button } from 'react-bootstrap'


import ModalNuevoUsuario from './ModalNuevoUsuario'


import ModalEditarUsuario from './ModalEditarUsuario'


import { rol } from '../../services/rol'



import ModalResetPasswordUsuario from './ModalResetPasswordUsuario'

import '../../rol_classes.scss'


const Usuarios = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 40
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`usuarios/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);




  const [ show_ModalNuevoUsuario, setModalNuevoUsuario ] = useState<boolean>(false)
  const handleShow_ModalNuevoUsuario = () => {
    setModalNuevoUsuario(true)
  }
  const handleClose_ModalNuevoUsuario = () => {
    busqueda()
    setModalNuevoUsuario(false)
  }


  const [ usuario_seleccionado, setUsuarioSeleccionado ] = useState<any>(null)
  const [ show_ModalEditarUsuario, setModalEditarUsuario ] = useState<boolean>(false)
  const handleShow_ModalEditarUsuario = (usuario:any) => {
    setUsuarioSeleccionado(usuario)
    setModalEditarUsuario(true)
  }
  const handleClose_ModalEditarUsuario = () => {
    busqueda()
    setModalEditarUsuario(false)
  }


  const [ show_ModalResetPasswordUsuario, setModalResetPasswordUsuario ] = useState<boolean>(false)
  const handleShow_ModalResetPasswordUsuario = (usuario:any) => {
     setUsuarioSeleccionado(usuario)
    setModalResetPasswordUsuario(true)
  }
  const handleClose_ModalResetPasswordUsuario = () => {
    setModalResetPasswordUsuario(false)
  }



      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Usuarios y Accesos</h4>
                    <hr/>



                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>

                        <div>
                          <Form.Check inline name="group1" type="checkbox" label="CAPTURA" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                        </div>

                        <ul className="list-inline">
                          <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalNuevoUsuario()}}>Nuevo Usuario</small></li>
                          <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                        </ul>

                    </div>


            


                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5 className="mt-4">Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Usuario</th>
                                              <th scope="col">Nombre</th>
                                              <th scope="col">Apellidos</th>
                                              <th scope="col">CURP</th>
                                              <th scope="col">Celular</th>
                                              <th scope="col">Rol</th>
                                              <th scope="col">Región</th>
                                              <th scope="col">Microregión</th>
                                              <th scope="col">Zona</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (usuario:any,indice_usuario:number) =>
                                                  <tr key={indice_usuario} style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm">{usuario.username}</p>
                                                    </td>
                                                    <td className="v text-left" >
                                                        <p className="nm"><b>{usuario.nombres}</b></p>
                                                    </td>
                                                    <td className="v text-left" >
                                                        <small>{usuario.paterno} {usuario.materno}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><b>{usuario.curp}</b></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm">{usuario.celular}</p>
                                                    </td>

                                                    <td className={`v text-left ${usuario.rol}`} style={{"width":"200px"}}>
                                                        <p className="nm">{rol(usuario.rol)}</p>
                                                    </td>

                                                    <td className="v text-center" style={{"width":"40px"}}>
                                                        
                                                        {
                                                          ["ROL_REGION","ROL_MICROREGION","ROL_ZONA","ROL_AREA"].indexOf(usuario.rol) > -1 && (<p className="nm">{usuario.asignamiento?.region}</p>)
                                                        }

                                                        {
                                                          // ["ROL_AREA"].indexOf(usuario.rol) > -1 && (<p className="nm">{usuario.region}</p>)
                                                        }
                                                        
                                                    </td>

                                                    <td className="v text-center" style={{"width":"40px"}}>
                                                        {
                                                          ["ROL_MICROREGION","ROL_ZONA","ROL_AREA"].indexOf(usuario.rol) > -1 && (<p className="nm">{usuario.asignamiento?.microregion}</p>)
                                                        }

                                                        {
                                                          // ["ROL_AREA"].indexOf(usuario.rol) > -1 && (<p className="nm">{usuario.microregion}</p>)
                                                        }


                                                    </td>

                                                    <td className="v text-center" style={{"width":"40px"}}>
                                                        {
                                                          ["ROL_ZONA","ROL_AREA"].indexOf(usuario.rol) > -1 && (<p className="nm">{usuario.asignamiento?.zona}</p>)
                                                        }
                                                        {
                                                          // ["ROL_AREA"].indexOf(usuario.rol) > -1 && (<p className="nm">{usuario.microregion}</p>)
                                                        }
                                                    </td>




                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                    <Button title="Cambiar Contraseña" disabled={!usuario.activo} variant="warning" onClick={()=>{handleShow_ModalResetPasswordUsuario(usuario)}} size="sm"><i className="fa fa-key"/></Button>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                        <button className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>
                                                    </td>



                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                    
                </div>
              </div>

            </div>





            {
              show_ModalNuevoUsuario && (
                <ModalNuevoUsuario show={show_ModalNuevoUsuario} handleClose={handleClose_ModalNuevoUsuario} />
                )

            }



  {
    show_ModalEditarUsuario && (
      <ModalEditarUsuario show={show_ModalEditarUsuario} handleClose={handleClose_ModalEditarUsuario} usuario={usuario_seleccionado} />
      )

  }





  {
    show_ModalResetPasswordUsuario && (
      <ModalResetPasswordUsuario show={show_ModalResetPasswordUsuario} handleClose={handleClose_ModalResetPasswordUsuario} usuario={usuario_seleccionado} />
      )

  }









          </>

      );
  

}

export default Usuarios
