import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'



import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'


const ModalNuevoUsuario = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params





  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          curp : yup.string().required(mensajes_validacion_generico),
          celular : yup.number().required(mensajes_validacion_generico),
          nombres : yup.string().required(mensajes_validacion_generico),
          paterno : yup.string().required(mensajes_validacion_generico),
          materno : yup.string(),
          rol : yup.string().required(mensajes_validacion_generico),

    region: yup.number().nullable().when('rol', {
        is: 'ROL_REGION',
        then: yup.number().required(mensajes_validacion_generico),
        otherwise: yup.number().nullable(),
    }),
    microregion: yup.number().nullable().when('rol', {
        is: 'ROL_MICROREGION',
        then: yup.number().required(mensajes_validacion_generico),
        otherwise: yup.number().nullable(),
    }),
    zona: yup.number().nullable().when('rol', {
        is: 'ROL_ZONA',
        then: yup.number().required(mensajes_validacion_generico),
        otherwise: yup.number().nullable(),
    }),
    area: yup.number().nullable().when('rol', {
        is: 'ROL_AREA',
        then: yup.number().required(mensajes_validacion_generico),
        otherwise: yup.number().nullable(),
    }),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)

          if(registro.region === "") registro.region = null
          if(registro.microregion === "") registro.microregion = null
          if(registro.zona === "") registro.zona = null
          if(registro.area === "") registro.area = null

          let res_ = await RequestService.create( `usuarios/agregar_usuario` , { ...registro })

          if(res_){
            toast.success('El usuario fué agregado. Ahora puede acceder al sistema')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }



const areas = [
  { zona : 1, secciones : [151,152,149,150,155,156,147,148,154,153,162,158,159,160,157,167,168,169,170,171,161,164,165,166,163,182] },
  { zona : 2, secciones : [369,370,372,179,178,181,172,173,174,180,177,175,176] },
  { zona : 3, secciones : [368,385,373,373,374,375,376,377,378,379,371,366,367,387,363,364,365,386,388,391,389,392,390,380] },
  { zona : 4, secciones : [381,383,382,384,408,406,407,393,394,395,396,397,398,399,400,401,402,403,404,405,412,413,409,410,411] },
  { zona : 5, secciones : [362,414,415,419,416,417,418,425,424,423,422,421,420,426,427] },
  { zona : 6, secciones : [447,446,445,444,443,441,442,440] },
  { zona : 7, secciones : [133,134,135,128,127,124,136,139,140,141,137,138,143,142,146,144,145] },
  { zona : 8, secciones : [3,4,7,11,12,10,21,20,19,15,16,14,13,33,35,32,34,36,37,22,23] },
  { zona : 9, secciones : [552,553,551,550,549,548] },
  { zona : 10, secciones : [126,39,40,41,42,43,44,45,60,61,62,63,64,65,66,67,79,80,82,83,125] },
  { zona : 11, secciones : [31,30,28,51,29,27,50,52,49,48,47,26,55,56,58,57,59,69,70,71,77,78,46,85,84,68,76,95,94,96,86,92,93,25,24,12] },
  { zona : 12, secciones : [54,53,87,90,91,75,99,100,73,101,74,102,103,107,109,72,88,111,541,540] },
  { zona : 13, secciones : [117,116,115,114,113,112,106,105,104,110,98,97,89] },
  { zona : 14, secciones : [129,131,121,132,123,130,120,119,118,38,17,18,8,9,5,2,1,6,122] },
  { zona : 15, secciones : [360,359,357,356,358,361,448,453,449,450,451,452,454,470,469,467,468,458,459,461,460,462,463,464,457,456,489] },
  { zona : 16, secciones : [261,262,259,260,270,271,484,472,487,473,479,483,474,478,284,486,481,482,476,485,488,477,475,480,455,465,466,471] },
  { zona : 17, secciones : [428,429,439,436,435,432,272,433,434,430,431] },
  { zona : 18, secciones : [536,531,533,534,535,532,274,275,276,277,537,279,280,281,286,287,288,289,282,283,285,291,292,290,296,294,295,293,297,298,299,300,301,302,490] },
  { zona : 19, secciones : [249,248,247,246,245,243,242,241,240,235,234,233,232,228,227,220,219,208,205,204] },
  { zona : 20, secciones : [226,217,218,214,213,212,211,209,210,203,202,201,197,196,195,194,192,207,191,190,189,188,187,186] },
  { zona : 21, secciones : [269,268,267,266,265,264,263,239,237,236,225,224,223,222,221,215,216,200,199] },
  { zona : 22, secciones : [507,506,505,504,503,502,500,499,498,497,496,495,494,493,206,193,185,542,543,544,547,546,545] },
  { zona : 23, secciones : [492,491,554,557,555,556,527,526,525,524,523,522,521,520,519,518,517,516,515,514,513,512,511,510,509,508,501,231,230] },
  { zona : 24, secciones : [529,528,339,336,334,333,332,538,330,329,326,327,328,539,320,321,322,323,324,325] },
  { zona : 25, secciones : [340,345,346,347,348,530,342,341,338,337,335,354,355,353,352,350,351,349,343,344] },
  { zona : 26, secciones : [250,256,257,253,319,318,317,316,315,314,313,312,311,310,309,252,254,308,258,307,255,251,306,305,304,303] }
]


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            curp : ``,
            // curp : `${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${Math.floor(Math.random() * 10)}`,
            celular : '',
            nombres : "",
            paterno : "",
            materno : "",
            rol : "",

            region : '',
            microregion : '',
            zona : '',
            area : '',

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="curp">
              <Form.Label>CURP</Form.Label>
              <Form.Control
                required
                name="curp"
                value={values.curp}
                onBlur={(e)=>{ setFieldValue('curp', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('curp', e.target.value ) }}
                isInvalid={!!errors.curp}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.curp}</>} </Form.Control.Feedback>
            </Form.Group>



            <Form.Group className="mb-3" controlId="curp">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                required
                name="celular"
                value={values.celular}
                onBlur={(e)=>{ setFieldValue('celular', e.target.value ) }}
                onChange={(e)=>{ setFieldValue('celular', e.target.value ) }}
                isInvalid={!!errors.celular}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.curp}</>} </Form.Control.Feedback>
            </Form.Group>



            <Form.Group className="mb-3" controlId="nombres">
              <Form.Label>Nombre(s)</Form.Label>
              <Form.Control
                required
                name="nombres"
                value={values.nombres}
                onBlur={(e)=>{ setFieldValue('nombres', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('nombres', e.target.value ) }}
                isInvalid={!!errors.nombres}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.nombres}</>} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="paterno">
              <Form.Label>Paterno</Form.Label>
              <Form.Control
                required
                name="paterno"
                value={values.paterno}
                onBlur={(e)=>{ setFieldValue('paterno', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('paterno', e.target.value ) }}
                isInvalid={!!errors.paterno}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.paterno}</>} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="materno">
              <Form.Label>Materno</Form.Label>
              <Form.Control
                required
                name="materno"
                value={values.materno}
                onBlur={(e)=>{ setFieldValue('materno', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('materno', e.target.value ) }}
                isInvalid={!!errors.materno}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.materno}</>} </Form.Control.Feedback>
            </Form.Group>




          <Form.Group className="mb-3" controlId="rol">
            <Form.Label>Privilegios</Form.Label>
            <Form.Control
              as="select"
              name="rol"
              value={values.rol}
              onChange={(e) => setFieldValue('rol', e.target.value)}
              isInvalid={!!errors.rol}
            >
              <option value="">Selecciona un privilegio</option>
              <option value="ROL_REGION">Responsable de Región</option>
              {/*<option value="ROL_MICROREGION">Responsable de Microregión</option>*/}
              <option value="ROL_ZONA">Responsable de Zona</option>
              <option value="ROL_AREA">Capturista</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {<>{errors.rol}</>}
            </Form.Control.Feedback>
          </Form.Group>


          {
            values.rol === "ROL_REGION" && (

                <Form.Group className="mb-3" controlId="region">
                  <Form.Label>Región</Form.Label>
                  <Form.Control
                    as="select"
                    name="region"
                    value={values.region}
                    onChange={(e) => setFieldValue('region', e.target.value)}
                    isInvalid={!!errors.region}
                  >
                    <option value="">Seleccion la región</option>
                    <option value="1">Región 1</option>
                    <option value="2">Región 2</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {<>{errors.region}</>}
                  </Form.Control.Feedback>
                </Form.Group>

              )
          }


{/*

          {
            values.rol === "ROL_MICROREGION" && (

                <Form.Group className="mb-3" controlId="microregion">
                  <Form.Label>Región</Form.Label>
                  <Form.Control
                    as="select"
                    name="microregion"
                    value={values.microregion}
                    onChange={(e) => setFieldValue('microregion', e.target.value)}
                    isInvalid={!!errors.microregion}
                  >
                    <option value="">Seleccion la microregión</option>
                    <option value="1">Microregión 1</option>
                    <option value="2">Microregión 2</option>
                    <option value="3">Microregión 3</option>
                    <option value="4">Microregión 4</option>
                    <option value="5">Microregión 5</option>
                    <option value="6">Microregión 6</option>
                    <option value="7">Microregión 7</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {<>{errors.microregion}</>}
                  </Form.Control.Feedback>
                </Form.Group>

              )
          }
*/}






          {
            values.rol === "ROL_ZONA" && (

                <Form.Group className="mb-3" controlId="zona">
                  <Form.Label>Zona</Form.Label>
                  <Form.Control
                    as="select"
                    name="zona"
                    value={values.zona}
                    onChange={(e) => setFieldValue('zona', e.target.value)}
                    isInvalid={!!errors.zona}
                  >
                    <option value="">Seleccion la zona</option>
                    <option value="1">Zona 1 (CALKINI)</option>
                    <option value="2">Zona 2 (DZITBALCHE-CALKINI)</option>
                    <option value="3">Zona 3 (HECELCHAKAN-HOPELCHEN)</option>
                    <option value="4">Zona 4 (HOPELCHEN-CALAKMUL)</option>
                    <option value="5">Zona 5 (CALAKMUL)</option>
                    <option value="6">Zona 6 (TENABO)</option>
                    <option value="7">Zona 7 (CAMPECHE)</option>
                    <option value="8">Zona 8 (CAMPECHE)</option>
                    <option value="9">Zona 9 (CAMPECHE)</option>
                    <option value="10">Zona 10 (CAMPECHE)</option>
                    <option value="11">Zona 11 (CAMPECHE)</option>
                    <option value="12">Zona 12 (CAMPECHE)</option>
                    <option value="13">Zona 13 (CAMPECHE)</option>
                    <option value="14">Zona 14 (CAMPECHE)</option>
                    <option value="15">Zona 15 (ESCARCEGA-CALAKMUL)</option>
                    <option value="16">Zona 16 (ESCARCEGA-CARMEN)</option>
                    <option value="17">Zona 17 (PALIZADA-CARMEN)</option>
                    <option value="18">Zona 18 (CANDELARIA)</option>
                    <option value="19">Zona 19 (CARMEN)</option>
                    <option value="20">Zona 20 (CARMEN)</option>
                    <option value="21">Zona 21 (CARMEN)</option>
                    <option value="22">Zona 22 (CARMEN)</option>
                    <option value="23">Zona 23 (CARMEN)</option>
                    <option value="24">Zona 24 (SEYBAPLAYA-CHAMPOTON)</option>
                    <option value="25">Zona 25 (CHAMPTON)</option>
                    <option value="26">Zona 26 (CHAMPOTON-CARMEN)</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {<>{errors.zona}</>}
                  </Form.Control.Feedback>
                </Form.Group>

              )
          }








          {
            values.rol === "ROL_AREA" && (

                <Form.Group className="mb-3" controlId="area">
                  <Form.Label>Área</Form.Label>
                  <Form.Control
                    as="select"
                    name="area"
                    value={values.area}
                    onChange={(e) => setFieldValue('area', e.target.value)}
                    isInvalid={!!errors.area}
                  >
                    <option value="">Seleccion la área</option>

                    {areas.map(area => (
                      <optgroup key={area.zona} label={`Zona ${area.zona}`}>
                        {area.secciones
                          .slice() // Hacemos una copia para no modificar el array original
                          .sort((a, b) => b - a) // Orden descendente
                          .map((seccion:number,index:number) => (
                            <option key={index} value={seccion}>
                              Área {seccion}
                            </option>
                          ))}
                      </optgroup>
                    ))}



                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {<>{errors.area}</>}
                  </Form.Control.Feedback>
                </Form.Group>

              )
          }













        </Form>
      )}
    </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar Ventana
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevoUsuario;