import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'

import { SERVER_ } from '../../config'

const ModalReporteAvancesCensoExcel = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // const descargaReporteAvanceExcel = () => {
  //   // window.open(`${SERVER_}censo/folios/reporte/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`)
  // }

const descargaReporteAvanceExcel = async () => {
    try {
        // Solicita el token de seguridad
        // const token = 'tu_token_de_seguridad';

        // Realiza la solicitud GET con el token en el encabezado
        const response = await fetch(`${SERVER_}censo/folios/reporte/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${AuthService.getToken()}`
            }
        });

        // Verifica si la respuesta es exitosa
        if (!response.ok) {
            throw new Error('Error al descargar el archivo');
        }

        // Convierte la respuesta en un blob
        const blob = await response.blob();

        // Crea una URL para el archivo
        const urlArchivo = window.URL.createObjectURL(blob);

        // Crea un enlace para iniciar la descarga
        const enlace = document.createElement('a');
        enlace.href = urlArchivo;

        let nombreArchivo = `reporte_finanzas_${moment(startDate).format('YYYY_MM_DD_HH_mm')}.xlsx`; // Nombre por defecto

        enlace.download = nombreArchivo;
        document.body.appendChild(enlace);
        enlace.click();

        // Limpia el URL del archivo y el enlace creado
        window.URL.revokeObjectURL(urlArchivo);
        document.body.removeChild(enlace);

    } catch (error) {
        console.error('Error:', error);
    }
}

  return (
    <>
      <Modal show={show} size="sm" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Avances</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(user)
          }


            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" type="button" onClick={descargaReporteAvanceExcel}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalReporteAvancesCensoExcel;