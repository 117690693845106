import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalEditarSeccion from './ModalEditarSeccion'


const Secciones = () =>{



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 50
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ secciones, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`secciones/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);



  const [ show_ModalEditarSeccion, setModalEditarSeccion ] = useState<boolean>(false)
  const [ seccion_seleccionada, setSeccionSeleccionada ] = useState<any>(null)
  const handleShow_ModalEditarSeccion = (seccion:any) => {
    setSeccionSeleccionada(seccion)
    setModalEditarSeccion(true)
  }
  const handleClose_ModalEditarSeccion = () => {
    busqueda()
    setModalEditarSeccion(false)
  }


      return (
          <>
            <div className="container">

                    <h4>Secciones</h4>
                    <hr/>
                    



                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>
                        
                    </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     secciones.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th className="text-center" scope="col">Sección</th>
                                              <th scope="col">Municipio</th>
                                              <th className="text-center" scope="col">Meta</th>
                                              <th className="text-center" scope="col">Folios</th>
                                              <th className="text-center" scope="col">Viviendas</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              secciones.map( (seccion:any,indice:number) =>
                                                  <tr key={indice} >
                                                    <td className="v text-center" style={{"width":"120px"}}>
                                                        <h3 className="nm">{seccion.seccion}</h3>
                                                    </td>
                                                    <td className="v text-left">
                                                        {seccion.municipio}
                                                    </td>
                                                    <td className="v text-center" style={{"width":"80px"}}>
                                                        <p className="nm"><><>{seccion.meta}</></></p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"80px"}}>
                                                        <p className="nm"><><>{seccion.total_folios}</></></p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"80px"}}>
                                                        <p className="nm"><><>{seccion.total_viviendas}</></></p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"40px"}}>
                                                        <button onClick={(e:any)=>{handleShow_ModalEditarSeccion(seccion)}} className="btn btn-sm"><i className="fa fa-edit"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



            </div>



  {
    show_ModalEditarSeccion && (
      <ModalEditarSeccion show={show_ModalEditarSeccion} handleClose={handleClose_ModalEditarSeccion} seccion={seccion_seleccionada} />
      )

  }



          </>

      );
  

}

export default Secciones
