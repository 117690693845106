import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import { Col, Row, Card } from 'react-bootstrap'

import moment from 'moment'
import 'moment/locale/es'

import './avances.scss'

const Avances = () =>{

      const [ avance_general, setRegistro ] = useState<any>([])
      const [ cargando_, setCargando ] = useState<any>( false )

      const cargar = async () =>{
        try{
            setCargando(true)
            let res = await RequestService.getEndPoint(`avance_general`)
            setRegistro(res.data)
            setCargando(false)

        }
        catch(e){
          setCargando(false)
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);


      return (
          <>
            <div className="container-fluid">


                    <h4>Avances</h4>
                    <hr/>

                    {
                      !cargando_ && (
                          <>

                            {
                              avance_general.map((region:any,indice_region:number)=>
                                  <React.Fragment key={indice_region}>
                                    <h4>{region.region}</h4>

                                            <Row className="g-2">

                                                      {
                                                      region.microregiones?.map((microregion:any,indice_microregion:number)=>
                                                        <Col xs={12} md={6} lg={3} key={indice_microregion} >
                                                          <Card>
                                                            <Card.Body>
                                                              <Card.Title>{microregion.responsable?.nombres} {microregion.responsable?.paterno} {microregion.responsable?.materno}</Card.Title>
                                                          {
                                                            // JSON.stringify(microregion.responsable)
                                                          }
                                                              <Card.Subtitle>{microregion.microregion}</Card.Subtitle>
                                                              {microregion.zonas.map((zona:any, i:number) => (
                                                                <div key={i} style={{ marginBottom: '10px' }} className="col-with-divider">

      <Row className="justify-content-center text-center">
        <Col xs={3} md={3} lg={2}>
            <h5>X</h5> {/* Cambia 'X' por el icono deseado */}
            <p>{zona.viviendas}</p>
        </Col>
        <Col xs={3} md={3} lg={2}>
            <h5>X</h5>
            <p>{zona.folios}</p>
        </Col>
        <Col xs={3} md={3} lg={2}>
            <h5>X</h5>
            <p>{zona.adultos}</p>
        </Col>
        <Col xs={3} md={3} lg={2}>
            <h5>X</h5>
            <p>{zona.adultos_hombres}</p>
        </Col>
        <Col xs={3} md={3} lg={2}>
            <h5>X</h5>
            <p>{zona.adultos_mujeres}</p>
        </Col>
        <Col xs={3} md={3} lg={2}>
            <h5>X</h5>
            <p>{zona.discapacidad}</p>
        </Col>
        <Col xs={3} md={3} lg={2}>
            <h5>X</h5>
            <p>{zona.discapacidad_hombres}</p>
        </Col>
        <Col xs={3} md={3} lg={2}>
            <h5>X</h5>
            <p>{zona.discapacidad_mujeres}</p>
        </Col>
      </Row>
                                                                  <p className="nm"><strong>Zona</strong> {zona.zona} <b>{zona.municipio}</b></p>
                                                                  <p className="nm"><strong>Responsable</strong> {zona.responsable?.nombres} {zona.responsable?.paterno} {zona.responsable?.materno}</p>
                                                                  <p className="nm"><strong>Secciones ({zona.num_secciones}):</strong> {zona.secciones.join(', ')}</p>
                                                                </div>
                                                              ))}
                                                            </Card.Body>
                                                          </Card>
                                                        </Col>
                                                      )
                                                    }

                                          </Row>



                                    {

                                    }
                                  </React.Fragment>
                                )
                            }

                          </>
                        )
                    }




                            <div className="row">
                                <div className="col-12 col-md-3">
                                  ---
                                </div>
                                <div className="col-12 col-md-9">

                                    {
                                      // JSON.stringify(avance_general)
                                    }

                                </div>
                            </div>





                    

            </div>

          </>

      );
  

}

export default Avances
